import React from 'react'
import '../css/footer.css'

export default function Footer() {
    return (
        <div className='footer-wrapper'>
            <div className='container footer-inner'>
                <h5>Copyright &copy; 2020. SIMPLE PROCESS INC.</h5>
                <p>Call us at <a href='tel:(307) 365-6940'>(307) 365-6940</a></p>
            </div>
        </div>
    )
}