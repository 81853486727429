import React from 'react'
import '../css/complaints.css'
import ComplaintCard from './ComplaintCard'
import card1 from '../images/card1.jpg'
import card2 from '../images/card2.jpg'
import card3 from '../images/card3.jpg'

export default function Complaints() {
    return (
        <div className='complaints-wrapper'>
            <div className='container complaints-inner'>
                <div className='complaints-header'>
                    <h4>Timeshare owners have many complaints.</h4>
                </div>
                <div className='complaints-body'>
                    <ComplaintCard image={card1} text1='Have you been unable to sell?' />
                    <ComplaintCard card2={true} image={card2} text1='Do you have problems with the reservation and exchange system?' />
                    <ComplaintCard image={card3} text1='Have your fees increased?' />
                </div>
                <div className='complaints-header' >
                    <h4>Simple Process Inc. can help with your timeshare challenges.</h4>
                </div>
            </div>
        </div>
    )
}