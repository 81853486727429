import React from 'react'
import '../css/header.css'
import logo from '../images/simple_process_inc.png'

export default function Header() {
    return (
        <div className='header-wrapper sticky-top'>
            <div className='container header-inner'>
                <div className='logo-wrapper'>
                    <img src={logo} alt='Logo' />
                </div>
            </div>
        </div>
    )
}