import React from 'react'
import '../css/send_report.css'
import Modal from './Modal'

export default function SendReport() {
    return (
        <div className='send-report-wrapper'>
            <Modal />
            <div className='send-report-inner'>
                <h2>GET FREE CONSULTATION</h2>
                <p>CLICK HERE TO REQUEST INFORMATION</p>
                <button type='button' data-toggle='modal' data-target='#exampleModal'>Request Information</button>
                <p>Privacy Policy: We hate SPAM and promise to keep your email<br />address safe.</p>
            </div>
        </div>
    )
}