import React from 'react'
import '../css/complaints.css'

export default function ComplaintCard({image, text1, text2, card2}) {
    return (
        <div className='card-wrapper'>
            <div className={`card-img-wrapper ${card2 && 'card2'}`}>
                <img src={image} alt='' />
            </div>
            <div className='card-text-wrapper'>
                {card2 ? <p>{text1}<br />{text2}</p> : <p>{text1}<br /><br />{text2}</p>}
            </div>
        </div>
    )
}