import React from 'react'
import '../css/modal.css'
import '../css/consultation.css'

export default function Modal() {
    async function validateForm(e) {
        e.preventDefault();
        let name = document.getElementById('name').value;
        let email = document.getElementById('email').value;
        let message = document.getElementById('message').value
        let phone_number = document.getElementById('phone').value;
        let resort_name = document.getElementById('resort_name').value;

        try {
            await fetch(`https://simpleprocessinc.com/email/index.php?email=${email}&name=${name}&phone=${phone_number}&resort_name=${resort_name}&msg=${message}`, {
                method: "POST",
                mode: "no-cors",
            });
            window.location.reload();
        } catch (e) {
            console.log(e);
            alert('Something went wrong')
        }
        
    }
    return (

        // Modal
        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                        Timeshare Owner Name
                        Timeshare Owner Phone Number
                        Timeshare Owner Email Address
                        Resort Name
                        Message / Questions
                    </div> */}
                    <div className="modal-body">
                        <div className="container consultation-container">
                            <p className="text-center title">GET FREE CONSULTATION</p>
                            <form onSubmit={(e) => {
                                validateForm(e);
                            }}>
                                <div className="form-group">
                                    <input type="text" minLength="2" id="name" name="name" placeholder="Timeshare Owner Name" required />
                                </div>
                                <div className="form-group">
                                    <input type="email" minLength="2" id="email" name="email" placeholder="Timeshare Owner Email Address" required />
                                </div>
                                <div className="form-group">
                                    <input type="text" minLength="2" id="phone" name="phone" placeholder="Timeshare Owner Phone Number" required />
                                </div>
                                <div className="form-group">
                                    <input type="text" minLength="2" id="resort_name" name="resort_name" placeholder="Resort Name" required />
                                </div>
                                <div className="form-group">
                                    <textarea type="text" minLength="2" id="message" name="message" placeholder="Message/Questions" required />
                                </div>
                                <div className="form-group consultation-btn-wrapper">
                                    <button type="submit" className="btn btn-primary">Click here to request information</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}