import React from 'react'
import '../css/caption.css'
import caption_img from '../images/caption_img.jpg'
import Modal from './Modal'

export default function Caption() {
    return (
        <div className='caption-wrapper'>
            <Modal />
            <div className='container caption-inner'>
                <div className='caption-content-left'>
                    <h3>Simple Process Inc. works with unhappy timeshare owners, and helps them get out of their timeshare.<br /><br />We have a simple process that is effective.</h3>
                    <button type='button' data-toggle='modal' data-target='#exampleModal'>Request Information</button>
                </div>
                <div className='caption-content-right'>
                    <img src={caption_img} alt='' />
                    {/* https://www.youtube.com/watch?time_continue=2&v=A2aCX4PDqfo&feature=emb_logo
                    <iframe title='timeshare-getout' src="https://www.youtube.com/embed/A2aCX4PDqfo?autoplay=1&amp;loop=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                </div>
            </div>
        </div>
    )
}